import React, { useState, useEffect } from 'react';

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

const root_url = process.env.REACT_APP_BACKEND_URL || '';

const ChatWidget: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // Chat is open by default
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false); // Loading state

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };


  const handleSendMessage = async () => {
    if (!message.trim()) return; // Don't send empty messages

    // Add the user's message to the chat
    const userMessage: Message = { text: message, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setMessage(''); // Clear the input field

    setLoading(true); // Start loading

    try {
      // Send the message to the backend and get the response
      const botResponse = await sendMessageToBackend(message);

      // Add the bot's response to the chat
      const botMessage: Message = { text: botResponse, sender: 'bot' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Failed to send message:', error);
      // Display an error message in the chat
      const errorMessage: Message = {
        text: 'Failed to send message. Please try again.',
        sender: 'bot',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to send a message to the backend
  const sendMessageToBackend = async (message: string): Promise<string> => {
    const response = await fetch(`${root_url}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
        credentials: 'include',
      body: JSON.stringify({ message }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.response; // Assume the backend returns { response: string }
  };

  // Effect to send the initial hidden request
  useEffect(() => {
    const sendInitialRequest = async () => {
      try {
        // Send a hidden "hi" request to the server
        const initialResponse = await sendMessageToBackend('hi');

        const botMessage: Message = { text: initialResponse, sender: 'bot' };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        // Optionally log the response for debugging
        console.log('Initial Response:', initialResponse);
      } catch (error) {
        console.error('Failed to send initial request:', error);
      }
    };

    // Call the function to send the initial request
    sendInitialRequest();
  }, []); // Run only once when the component mounts

  // Styles remain unchanged...

  // Styles
  const chatButtonContainerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
  };

  const chatButtonStyle: React.CSSProperties = {
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '50%',
    width: '70px',
    height: '70px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const profilePicStyle: React.CSSProperties = {
    width: '68px',
    height: '68px',
    borderRadius: '50%',
  };

  const chatWindowStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '90px', // Position above the button
    right: '20px',
    width: '300px',
    height: '400px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1000,
  };

  const chatHeaderStyle: React.CSSProperties = {
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    alignItems: 'center',
  };

  const chatBodyStyle: React.CSSProperties = {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  };

  const chatFooterStyle: React.CSSProperties = {
    padding: '10px',
    borderTop: '1px solid #ddd',
    display: 'flex',
  };

  const chatInputStyle: React.CSSProperties = {
    flex: 1,
    padding: '5px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginRight: '10px',
  };

  const sendButtonStyle: React.CSSProperties = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  const messageStyle = (sender: 'user' | 'bot'): React.CSSProperties => ({
    alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
    backgroundColor: sender === 'user' ? '#007bff' : '#f1f1f1',
    color: sender === 'user' ? 'white' : 'black',
    padding: '8px 12px',
    borderRadius: '10px',
    maxWidth: '80%',
  });

  const loadingDotsStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  };

  const dotStyle: React.CSSProperties = {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: '#007bff',
    animation: 'bounce 1.4s infinite ease-in-out',
  };

  const dot1Style: React.CSSProperties = {
    ...dotStyle,
    animationDelay: '0s',
  };

  const dot2Style: React.CSSProperties = {
    ...dotStyle,
    animationDelay: '0.2s',
  };

  const dot3Style: React.CSSProperties = {
    ...dotStyle,
    animationDelay: '0.4s',
  };

  return (
    <>
      {/* Chat Button */}
      <div style={chatButtonContainerStyle}>
        <button style={chatButtonStyle} onClick={toggleChat}>
          <img
            src="img/me.jpeg" // Replace with your profile picture URL
            alt="Profile"
            style={profilePicStyle}
          />
        </button>
      </div>

      {/* Chat Window */}
      {isOpen && (
        <div style={chatWindowStyle}>
          <div style={chatHeaderStyle}>
            <img
              src="img/me.jpeg" // Replace with your profile picture URL
              alt="Profile"
              style={{ ...profilePicStyle, width: '30px', height: '30px', marginRight: '10px' }}
            />
            <span>How can we support you?</span>
          </div>
          <div style={chatBodyStyle}>
            {messages.map((msg, index) => (
              <div key={index} style={messageStyle(msg.sender)}>
                {msg.text}
              </div>
            ))}
            {loading && (
              <div style={{ ...messageStyle('bot'), ...loadingDotsStyle }}>
                <div style={dot1Style}></div>
                <div style={dot2Style}></div>
                <div style={dot3Style}></div>
              </div>
            )}
          </div>
          <div style={chatFooterStyle}>
            <input
              type="text"
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              style={chatInputStyle}
            />
            <button onClick={handleSendMessage} style={sendButtonStyle}>
              Send
            </button>
          </div>
        </div>
      )}

      {/* CSS for the loading dots animation */}
      <style>
        {`
          @keyframes bounce {
            0%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-6px);
            }
          }
        `}
      </style>
    </>
  );
};

export default ChatWidget;