import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.css';
import ChatWidget from './components/ChatWidget';

import Router from './router';
import i18n from './translation';
import { AuthProvider } from './context/auth/AuthContext';

const App = () => (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
        <ChatWidget />
      </I18nextProvider>
    </BrowserRouter>
  );

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);